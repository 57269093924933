.componentBase {
  font-family: var(--font-family-base);
  line-height: var(--line-height-heading);
}

.componentXs {
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-22);
  font-style: italic;
  letter-spacing: -1px;
}

.componentSm {
  font-size: var(--font-size-28);
  font-size: var(--font-size-28-32);
  font-style: italic;
  letter-spacing: -1px;
}

.componentMd {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-48);
  font-style: italic;
  letter-spacing: var(--letter-spacing-heading);
}

.componentLg {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-64);
  font-style: italic;
  letter-spacing: var(--letter-spacing-heading);
}

.componentXl {
  font-size: var(--font-size-36);
  font-size: var(--font-size-36-72);
  font-style: italic;
  letter-spacing: var(--letter-spacing-heading);
}

.componentXxl {
  font-size: var(--font-size-48);
  font-size: var(--font-size-48-132);
  font-style: italic;
  line-height: var(--line-height-heading-alt);
  letter-spacing: var(--letter-spacing-heading);
}
